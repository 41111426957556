<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import {
  required,
} from "vuelidate/lib/validators";

/**
 * Reports Sales component
 */
export default {
  page: {
    title: "Gerenciar Relatório de Vendas",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Gerenciar Relatório de Vendas",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Gerenciar Relatório de Vendas",
          active: true,
        },
      ],      
      showModal: false,
      tableHighlighted: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fieldAliases: {
        id: "#",
        title: "Título",
        total_students: "Número de Estudantes",
        status: "Status",
        actions: "Ações",
      },  
      form: {
        fname: "",
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    }; 
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    fields() {
      return [
        { key: "id", sortable: true, label: this.fieldAliases.id },
        { key: "title", sortable: true, label: this.fieldAliases.title },
        { key: "total_students", sortable: true, label: this.fieldAliases.total_students },
        { key: "status", sortable: true, label: this.fieldAliases.status },
        { key: "actions", sortable: true, label: this.fieldAliases.actions, thStyle: { width: "110px" } }
      ];
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  validations: {
    form: {
      fname: { required },
    },
    tooltipform: {
      fname: { required },
    },
    typeform: {
      name: { required },
    },
  },
  methods: {
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
    },

    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    handleNewCad(value) {
      this.showModal = value;
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" @newCad="handleNewCad" />
  </Layout>
</template>
